import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "leaflet/dist/leaflet.css";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './en.json';
import esTranslation from './es.json';
import { CrispProvider } from "./components/CrispProvider";
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    es: { translation: esTranslation }
  },
  lng: 'en', // Default language
  fallbackLng: 'en', // Fallback language
  interpolation: {
    escapeValue: false // React already escapes values
  }
});


createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
          <CrispProvider/>
        </ThemeProvider>
    </BrowserRouter>

  </React.StrictMode>
);
