import React from "react";
import {
  Box,
  Typography,
  Link,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  const handleInstagram = () => {
    window.location.href =
      "https://instagram.com/optimusfleets?igshid=MmIzYWVlNDQ5Yg==";
  };

  const handleFacebook = () => {
    window.location.href =
      "https://www.facebook.com/people/Optimus-Fleets-LLC/61550123614312/";
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        backgroundColor: "#000000",
        color: "white",
        padding: "3rem",
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        justifyContent: "space-evenly",
        alignItems: isSmallScreen ? "flex-start" : "center",
        overflowX: "hidden",
      }}
    >
      <Box>
        <Typography variant="body2" sx={{ padding: "0.5rem" }}>
          &copy; 2023 Optimus Fleets LLC. All rights reserved.
        </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", padding: "0.5rem" }}>
        <Link href="#" color="inherit" sx={{ marginBottom: "1rem" }}>
          Terms & Conditions
        </Link>
        <Link href="#" color="inherit">
          Privacy Policy
        </Link>
      </Box>

      <Box sx={{ padding: "0.5rem" }}>
        <Typography>CORPORATE HEADQUARTERS</Typography>

        <Typography>GLASSBORO NJ 08028</Typography>
      </Box>

      <Box sx={{  display: "flex" }}>
        <IconButton onClick={handleInstagram} color="inherit">
          <InstagramIcon />
        </IconButton>
        <IconButton onClick={handleFacebook} color="inherit">
          <FacebookIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            window.location.href = "tel:+18564433401";
          }}
          color="inherit"
        >
          <Typography> +1 (856) 443-3401</Typography>
        </IconButton>
      </Box>
    </Box>
  );
};

export default Footer;
