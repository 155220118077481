import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  useMediaQuery,
  Button,
} from "@mui/material";
import { useSpring, animated, useScroll } from "react-spring";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CardContainer = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundSize: "cover",
  backgroundPosition: "center",
  borderRadius: "5px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  transition: "box-shadow 0.2s ease-in-out",
  cursor: "pointer",
  "&:hover": {
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
  },
  width: "100%",
  height: "15rem",
}));

const ContentContainer = styled(CardContent)({
  position: "absolute",
  bottom: 0,
  color: "#fff",
  textShadow: "1px 1px 2px rgba(0, 0, 0, 0.6)",
});

const ImageCard = ({ imageUrl, title }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <CardContainer
      style={{ backgroundImage: `url(${imageUrl})`, opacity: 0.8 }}
    >
      <ContentContainer sx={{ left: isMobile ? "33%" : 0 }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ textDecoration: "underline" }}
        >
          {title}
        </Typography>
      </ContentContainer>
    </CardContainer>
  );
};

const DetailsSection = () => {
  const { t } = useTranslation();
  const { scrollYProgress } = useScroll();
  const fadeAnimation = useSpring({
    opacity: scrollYProgress.to([0, 0.1, 1], [0, 1, 1]),
    config: { mass: 1, tension: 120, friction: 14 }, // Adjust easing for a smoother animation
  });
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const navigateToReserve = () => {
    navigate("/book");
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "3rem",
      }}
    >
      <Typography
        variant="inherit"
        component={"div"}
        gutterBottom
        sx={{ fontSize: "2rem" }}
      >
        {t("Discover What We Offer You")}
      </Typography>

      <Box
        sx={{
          paddingBottom: "2rem",
          width: "60rem",
          display: "grid",
          gridTemplateColumns: isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)",
          gridTemplateRows: isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)",
          gap: "2.5rem",
          zIndex: 10,
        }}
      >
        <animated.div
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            opacity: fadeAnimation.opacity,
          }}
        >
          <div onClick={navigateToReserve}>
            <ImageCard
              imageUrl={"/assets/airport.jpg"}
              title={t("Airport Transfer")}
            />
          </div>
        </animated.div>
        <animated.div
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            opacity: fadeAnimation.opacity,
          }}
        >
          <div onClick={navigateToReserve}>
            <ImageCard
              imageUrl={"/assets/corporate.jpg"}
              title={t("Corporate Travel")}
            />
          </div>
        </animated.div>
        <animated.div
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            opacity: fadeAnimation.opacity,
          }}
        >
          <div onClick={navigateToReserve}>
            <ImageCard
              imageUrl={"/assets/event.jpg"}
              title={t("Special Event Services")}
            />
          </div>
        </animated.div>
        <animated.div
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            opacity: fadeAnimation.opacity,
          }}
        >
          <div onClick={navigateToReserve}>
            <ImageCard
              imageUrl={"/assets/cars.jpeg"}
              title={t("Charter Services")}
            />
          </div>
        </animated.div>
      </Box>

      <Button
        onClick={navigateToReserve}
        sx={{ marginBottom: "2rem" }}
        variant="contained"
        color="primary"
        size="large"
      >
        {t("More services")}
      </Button>

      <Box
        sx={{
          height: "100%",
          paddingBottom: "2rem",
          backgroundColor: "#f2f2f2",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Typography variant="h5" gutterBottom mt={4}>
          {t("Our Services")}
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          sx={{ mt: "1rem", margin: "0 auto", width: "70%" }}
        >
          {t(
            "Our services go beyond..."
          )}
        </Typography>

        <Typography variant="h5" gutterBottom mt={4}>
        {t("Your Safety is Our Priority")}
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          sx={{ mt: "1rem", margin: "0 auto", width: "70%" }}
        >
           {t("At Optimus Fleets LLC...")}
        </Typography>
      </Box>
    </Box>
  );
};

export default DetailsSection;
