import React from "react";
import { useTranslation } from "react-i18next";
import Fab from "@mui/material/Fab";
import LanguageIcon from "@mui/icons-material/Language";
import TranslateIcon from "@mui/icons-material/Translate";
import CallIcon from "@mui/icons-material/Call";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div style={{ zIndex: 10, display: "flex", flexDirection: "column" }}>
      <a href="tel:+18564433401">
      <Fab
        variant="extended"
        sx={{ position: "absolute", top: 105, right: 10, width: "auto" }}
        onClick={() => changeLanguage("en")}
      >
        <CallIcon />
        +1 (856) 443-3401
      </Fab>
      </a>
      <Fab
        variant="extended"
        sx={{ position: "absolute", top: 105, left: 10, width: "auto" }}
        onClick={() => changeLanguage("en")}
      >
        <LanguageIcon />
        English
      </Fab>

      <Fab
        variant="extended"
        sx={{ position: "absolute", top: 160, left: 10, width: "auto" }}
        onClick={() => changeLanguage("es")}
      >
        <TranslateIcon />
        Español
      </Fab>
    </div>
  );
}

export default LanguageSwitcher;
