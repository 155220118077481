import React from "react";
import { styled } from "@mui/system";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Divider,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const CardContainer = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#f0f4f8",
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
  transition: "box-shadow 0.2s ease-in-out",
  "&:hover": {
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
    transform: "scale(1.02)",
  },
}));

const CardContentContainer = styled(CardContent)({
  width: "100%",
  height: "100%",
  minHeight: "13.5rem",
});

const CardMediaContainer = styled(CardMedia)({
  height: "25rem",
  width: "100%",
});

const MediaCard = ({ title, content, imageUrl }) => {
  const { t } = useTranslation(); 
  return (
    <CardContainer>
      <CardMediaContainer
        component="img"
        image={imageUrl}
        alt="Card Image"
      />
      <CardContentContainer>
        <Typography variant="inherit" component="div" sx={{fontSize: '1.8rem'}}>
        {t(title)} 
        </Typography>
        <Divider sx={{ marginBottom: "1rem" }} />
        <Typography variant="inherit" component="div" sx={{fontSize: '1rem'}}>
        {t(content)}
        </Typography>
      </CardContentContainer>
    </CardContainer>
  );
};

const ThreeMediaCards = () => {
  return (
    <div style={{ backgroundColor: "#f2f2f2", height: "100%", padding: '2rem 0' }}>
      <Grid container justifyContent="center" spacing={2} style={{ height: "100%" }}>
        <Grid item xs={12} md={4} style={{ flexGrow: 1 }}>
          <MediaCard
            title="Luxury Fleet"
            content="Indulge in our exquisite collection of luxury vehicles, each meticulously maintained and equipped with top-of-the-line amenities. Enjoy a smooth and comfortable ride in style with our experienced chauffeurs."
            imageUrl="/assets/suv.jpg"
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ flexGrow: 1 }}>
          <MediaCard
            title="Professional Chauffeurs"
            content="Our team of highly-trained chauffeurs is dedicated to providing you with exceptional service. With an emphasis on safety, reliability, and professionalism, we guarantee a pleasant and stress-free journey, no matter the destination."
            imageUrl="/assets/media2.jpg"
          />
        </Grid>
        <Grid item xs={12} md={4} style={{ flexGrow: 1 }}>
          <MediaCard
            title="Tailored Services"
            content="We understand that every journey is unique. Our tailored services allow you to customize your experience, whether it's a business trip, airport transfer, or a special event. Expect unparalleled attention to detail and a seamless travel experience."
            imageUrl="/assets/media3.jpg"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ThreeMediaCards;
