import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Merriweather, sans-serif',
  },
  palette: {
    background: {
      default: "#f2f2f2",
    },
    primary: {
      main: '#000000',
    },
  },
});

export default theme;
