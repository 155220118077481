import React, { useState, useEffect } from "react";
import {
  AppBar,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  IconButton,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import DrawerComp from "./Drawer";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setValue(0);
    } else if (location.pathname === "/aboutus") {
      setValue(1);
    }
  }, [location.pathname]);

  const handleHomeClick = () => {
    navigate("/");
  };

  const handleFacebook = () => {
    window.location.href = "https://www.facebook.com/people/Optimus-Fleets-LLC/61550123614312/";
  };

  const handleInstagram = () => {
    window.location.href = "https://instagram.com/optimusfleets?igshid=MmIzYWVlNDQ5Yg==";
  };

  return (
    <React.Fragment>
      <AppBar
        sx={{
          background: "#000000",
          height: "6rem",
          justifyContent: "center",
        }}
      >
        <Toolbar>
          <Box onClick={handleHomeClick} style={{ display: "flex", cursor: "pointer"}}>
            <img src="assets/LOGO.jpg" alt="LOGO" width={270} loading="lazy"/>
          </Box>
          {isMatch ? (
            <>
              <DrawerComp />
            </>
          ) : (
            <div style={{ marginLeft: "auto",marginRight: "10.5rem", flex: 1 }}>
              <Tabs
                textColor="inherit"
                value={value}
                onChange={(e, value) => setValue(value)}
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "white",
                  },
                }}
                centered
              >
                <Tab
                  label={t("Home")} 
                  key="home"
                  component={Link}
                  to="/"
                  sx={{ fontSize: "1rem" }}
                />
                <Tab
                  label={t("About Us")} 
                  key="aboutus"
                  component={Link}
                  to="/aboutus"
                  sx={{ fontSize: "1rem" }}
                />
              </Tabs>
            </div>
          )}
          <Box>
            <IconButton onClick={handleInstagram} sx={{ color: "white" }}>
              <InstagramIcon sx={{ fontSize: 30 }} />
            </IconButton>
            <IconButton onClick={handleFacebook} sx={{ color: "white" }}>
              <FacebookIcon sx={{ fontSize: 30 }} />
            </IconButton>
          </Box>

        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Navbar;
