import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Paper,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  TextField,
  Box,
  FormHelperText,
  Card,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import { MapContainer, TileLayer } from "react-leaflet";
import AddressAutocomplete from "mui-address-autocomplete";
import emailjs from "@emailjs/browser";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import axios from "axios";

const BookingForm = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [serviceType, setServiceType] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const [pickupTime, setPickupTime] = useState("");
  const [pickupLocation, setPickupLocation] = useState(""); // Initialize to null
  const [dropoffLocation, setDropoffLocation] = useState(""); // Initialize to null
  const [passengers, setPassengers] = useState(1);
  const [luggage, setLuggage] = useState(0);
  const [mapCenter, setMapCenter] = useState({
    lat: 0,
    lng: 0,
  });

  const serviceTypes = [
    { id: 1, name: t("Airport") },
    { id: 2, name: t("Point to Point") },
    { id: 3, name: t("As Directed") },
  ];
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  
  // Step 1 validation state
  const [step1FormValid, setStep1FormValid] = useState(false);

  // Step 2 validation state
  const [step2FormValid, setStep2FormValid] = useState(false);
  const [mapType] = useState(""); // "pickup" or "dropoff"

  // New state for personal details step
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [notes, setNotes] = useState("");
  const handleNextStep = () => {
    if (
      (activeStep === 0 && step1FormValid) ||
      (activeStep === 1 &&
        step2FormValid &&
        isEmailValid(email) &&
        isPhoneNumberValid(phoneNumber))
    ) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handlePreviousStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleServiceTypeChange = (event) => {
    setServiceType(event.target.value);
  };

  const handleMapClick = (event) => {
    const { lat, lng } = event;
    if (mapType === "pickup") {
      setPickupLocation({ lat, lng });
    } else if (mapType === "dropoff") {
      setDropoffLocation({ lat, lng });
    }
  };

  const mapRef = useRef(); // Ref to hold the map instance

  const handlePickupLocationSelect = (description, lat, lng) => {
    if (!description) {
      return;
    }
    setPickupLocation(description);
    setMapCenter({ lat, lng });

    mapRef.current.flyTo([lat, lng], 15, {
      duration: 2, // Duration of animation in seconds
    });
  };

  const handleDropOffLocationSelect = (description, lat, lng) => {
    setDropoffLocation(description);
    setMapCenter({ lat: lat, lng: lng });
    mapRef.current.flyTo([lat, lng], 15, {
      duration: 2, // Duration of animation in seconds
    });
  };

  const [returnReservation, setReturnReservation] = useState(false);
  const [returnPickupDate, setReturnPickupDate] = useState("");
  const [returnPickupTime, setReturnPickupTime] = useState("");
  const [returnPickupLocation, setReturnPickupLocation] = useState("");

  const handleReturnReservationChange = (event) => {
    setReturnReservation(event.target.value === "yes");
  };

  const handleReturnLocationSelect = (description, lat, lng) => {
    setReturnPickupLocation(description);
    setMapCenter({ lat: lat, lng: lng });
    mapRef.current.flyTo([lat, lng], 15, {
      duration: 2, // Duration of animation in seconds
    });
  };
  // New validation function for Step 1
  const checkStep1FormValidity = () => {
    const requiredFields = [
      serviceType,
      pickupDate,
      pickupTime,
      pickupLocation,
      dropoffLocation,
      passengers,
      luggage,
    ];
  
    if (returnReservation) {
      requiredFields.push(returnPickupDate, returnPickupTime, returnPickupLocation);
    }
  
    const isAnyFieldEmpty = requiredFields.some(
      (field) => field === "" || field === null
    );
  
    setStep1FormValid(!isAnyFieldEmpty);
  };

  // New validation function for Step 2
  const checkStep2FormValidity = () => {
    const requiredFields = [firstName, lastName, email, phoneNumber];

    const isAnyFieldEmpty = requiredFields.some(
      (field) => field === "" || field === null
    );

    setStep2FormValid(!isAnyFieldEmpty);
  };

  useEffect(() => {
    checkStep1FormValidity();
  }, [
    serviceType,
    pickupDate,
    pickupTime,
    pickupLocation,
    dropoffLocation,
    passengers,
    luggage,
    returnReservation, // Include returnReservation here
    returnPickupDate,  // Include return reservation details here
    returnPickupTime,
    returnPickupLocation,
  ]);

  useEffect(() => {
    checkStep2FormValidity();
  }, [firstName, lastName, email, phoneNumber]);

  // New validation function for email format
  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // New validation function for phone number format
  const isPhoneNumberValid = (phoneNumber) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const convertToAMPM = (time) => {
    const timeParts = time.split(":");
    let hours = parseInt(timeParts[0]);
    const minutes = timeParts[1];
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    return `${hours}:${minutes}${ampm}`;
  };

  const convertToDate = (date) => {
    console.log('start', date);
    const options = { year: "numeric", month: "long", day: "numeric" };

    // Split the date string into year, month, and day
    const [year, month, day] = date.split('-').map(Number);

    // Create the Date object in local time zone
    const dateObject = new Date(year, month - 1, day); // month - 1 because months are zero-indexed in JavaScript
    console.log('end', dateObject.toLocaleDateString(undefined, options));

    // Return the formatted date string
    return dateObject.toLocaleDateString(undefined, options);
  };

  const [loading, setLoading] = useState(false);

  // State to toggle between form and success message
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => emailjs.init(process.env.REACT_APP_PUBLIC_KEY), []);

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   const serviceId = process.env.REACT_APP_SERVICE_ID;
  //   const templateId = process.env.REACT_APP_TEMPLATE_ID;
  //   const message = `Thank you for choosing our services.\n\nYour reservation is confirmed for ${convertToDate(
  //     pickupDate
  //   )} at ${convertToAMPM(
  //     pickupTime
  //   )}.\n\nService Type: ${serviceType}\nPick-Up Location: ${
  //     pickupLocation || "Not specified"
  //   }\nDrop-Off Location: ${
  //     dropoffLocation || "Not specified"
  //   }\nNumber of Passengers: ${passengers}\nNumber of Luggages: ${luggage}\nPhone Number: ${phoneNumber}\n\nWe look forward to serving you!`;

  //   try {
  //     setLoading(true);
  //     await emailjs.send(serviceId, templateId, {
  //       from_name: "Optimus Fleets LLC",
  //       name: firstName,
  //       recipient: email,
  //       message: message,
  //     });
  //     setSubmitted(true);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const subject = `Reservation Confirmation from Optimus Fleets LLC`;
  
    const pickupDetails = `Pick-Up Date: ${convertToDate(pickupDate)}
  Pick-Up Time: ${convertToAMPM(pickupTime)}
  Pick-Up Location: ${pickupLocation || "Not specified"}`;
  
    const dropoffDetails = `Drop-Off Location: ${dropoffLocation || "Not specified"}
  Number of Passengers: ${passengers}
  Number of Luggages: ${luggage}`;
  
    const returnDetails = returnReservation
      ? `Return Pick-Up Date: ${convertToDate(returnPickupDate)}
  Return Pick-Up Time: ${convertToAMPM(returnPickupTime)}
  Return Pick-Up Location: ${returnPickupLocation || "Not specified"}`
      : "Return Reservation: No";
  
    const message = `Hello ${firstName},\n\nThank you for choosing our services.\nYour reservation is confirmed for ${pickupDetails}.\n\nService Type: ${serviceType}\n${pickupDetails}\n${dropoffDetails}\n${returnDetails}\nPhone Number: ${phoneNumber}\nAppointment Notes: ${
      notes.length > 0 ? notes : "N/A"
    }\n\nWe look forward to serving you!\n\nBest Wishes,\nOptimus Fleets LLC team`;
  
    const data = {
      subject: subject,
      email: email,
      message: message,
    };
  
    try {
      setLoading(true);
      const response = await axios.post(
        "https://send-email-api-chi.vercel.app/api/send-email",
        data
      );
      // console.log("Response:", response.data); // Log the response
      setSubmitted(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Container
      maxWidth="lg"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "'flex-start'",
        minHeight: "100vh",
        paddingTop: "120px",
        paddingBottom: "40px",
      }}
    >
      <Paper elevation={3} sx={{ padding: 3 }}>
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>{t("Select Service Type")}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t("Personal Details")}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t("Review Details")}</StepLabel>
          </Step>
        </Stepper>
        {!submitted && (
          <Box sx={{ height: "100%", paddingBottom: 2, position: "relative" }}>
            <Grid
              container
              spacing={2}
              sx={{ height: "100%", width: activeStep == 0 ? "80vw" : "100%" }}
            >
              <Grid item md={6} xs={12}>
                {activeStep === 0 && (
                  <Box sx={{ marginTop: 3 }}>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                      <Select
                        value={serviceType}
                        onChange={handleServiceTypeChange}
                        fullWidth
                        required
                        displayEmpty
                        inputProps={{ "aria-label": "Select Service Type" }}
                      >
                        <MenuItem value="" disabled>
                          {t("Select Service Type")}
                        </MenuItem>
                        {serviceTypes.map((type) => (
                          <MenuItem key={type.id} value={type.name}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Box sx={{ display: "flex" }}>
                      <TextField
                        variant="outlined"
                        type="date"
                        fullWidth
                        required
                        value={pickupDate}
                        onChange={(e) => setPickupDate(e.target.value)}
                        helperText={t("Pick-Up Date")}
                        InputLabelProps={{ shrink: true }}
                        sx={{ marginBottom: 2, pr: 0.5 }}
                      />
                      <TextField
                        variant="outlined"
                        type="time"
                        fullWidth
                        required
                        value={pickupTime}
                        onChange={(e) => setPickupTime(e.target.value)}
                        helperText={t("Pick-Up Time")}
                        InputLabelProps={{ shrink: true }}
                        sx={{ marginBottom: 2, pl: 0.5 }}
                      />
                    </Box>
                    <Grid container spacing={1}>
                      <Grid item md={6} sm={6} xs={12}>
                        <AddressAutocomplete
                          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                          fields={["geometry"]}
                          onChange={(_, value) => {
                            if (value && value.description) {
                              handlePickupLocationSelect(
                                value.description,
                                value.geometry.location.lat(),
                                value.geometry.location.lng()
                              );
                            }
                          }}
                        />
                        <FormHelperText sx={{ marginLeft: "15px" }}>
                          {t("Pick-Up Location")}
                        </FormHelperText>
                        </Grid>
                      <br />
                      <Grid item md={6} sm={6} xs={12}>
                        <AddressAutocomplete
                          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                          fields={["geometry"]}
                          onChange={(_, value) => {
                            if (value && value.description) {
                              handleDropOffLocationSelect(
                                value.description,
                                value.geometry.location.lat(),
                                value.geometry.location.lng()
                              );
                            }
                          }}
                        />
                        <FormHelperText sx={{ marginLeft: "15px" }}>
                          {t("Drop-Off Location")}
                        </FormHelperText>
                        </Grid>
                      </Grid>
                    <br />
                    <Grid container spacing={1}>
                      <Grid item md={12} sm={6} xs={6}>
                        <TextField
                          variant="outlined"
                          type="number"
                          fullWidth
                          required
                          value={passengers}
                          onChange={(e) => setPassengers(e.target.value)}
                          helperText={t("Number of Passengers")}
                          InputLabelProps={{ shrink: true }}
                          sx={{ marginBottom: 2 }}
                          InputProps={{ inputProps: { min: 1 } }}
                        />
                      </Grid>
                      <Grid item md={12} sm={6} xs={6}>
                        <TextField
                          variant="outlined"
                          type="number"
                          fullWidth
                          required
                          value={luggage}
                          onChange={(e) => setLuggage(e.target.value)}
                          helperText={t("Number of Luggages")}
                          InputLabelProps={{ shrink: true }}
                          sx={{ marginBottom: 2 }}
                          InputProps={{ inputProps: { min: 0 } }}
                        />
                      </Grid>
                    </Grid>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                      <Select
                        value={returnReservation ? "yes" : "no"}
                        onChange={handleReturnReservationChange}
                        fullWidth
                      >
                        <MenuItem value="no">{t("No")}</MenuItem>
                        <MenuItem value="yes">{t("Yes")}</MenuItem>
                      </Select>
                      <FormHelperText>
                        {t("Need a return reservation?")}
                      </FormHelperText>
                    </FormControl>
                    {returnReservation && (
                      <>
                        <TextField
                          variant="outlined"
                          type="date"
                          fullWidth
                          value={returnPickupDate}
                          onChange={(e) => setReturnPickupDate(e.target.value)}
                          helperText={t("Return Pick-Up Date")}
                          InputLabelProps={{ shrink: true }}
                          sx={{ marginBottom: 2 }}
                        />
                        <TextField
                          variant="outlined"
                          type="time"
                          fullWidth
                          value={returnPickupTime}
                          onChange={(e) => setReturnPickupTime(e.target.value)}
                          helperText={t("Return Pick-Up Time")}
                          InputLabelProps={{ shrink: true }}
                          sx={{ marginBottom: 2 }}
                        />
                        <AddressAutocomplete
                          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                          fields={["geometry"]}
                          onChange={(_, value) => {
                            if (value && value.description) {
                              handleReturnLocationSelect(
                                value.description,
                                value.geometry.location.lat(),
                                value.geometry.location.lng()
                              );
                            }
                          }}
                        />
                        <FormHelperText sx={{ marginLeft: "15px" }}>
                          {t("Return Pick-Up Location")}
                        </FormHelperText>
                      </>
                    )}
                    <Grid container justifyContent="space-between">
                      <Button
                        variant="contained"
                        onClick={handleNextStep}
                        disabled={!step1FormValid}
                      >
                        {t("Next")}
                      </Button>
                    </Grid>
                  </Box>
                )}
                {activeStep === 1 && (
                  <Box
                    sx={{
                      width: isSmallScreen ? "100%" : "30vw",
                      padding: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>
                      {t("Personal Details")}
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      helperText={t("First Name")}
                      InputLabelProps={{ shrink: true }}
                      sx={{ marginBottom: 2 }}
                    />
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      helperText={t("Last Name")}
                      InputLabelProps={{ shrink: true }}
                      sx={{ marginBottom: 2 }}
                    />

                    <TextField
                      variant="outlined"
                      type="email"
                      fullWidth
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      helperText={t("Email Address")}
                      error={!isEmailValid(email)}
                      InputLabelProps={{ shrink: true }}
                      sx={{ marginBottom: 2 }}
                    />
                    <TextField
                      variant="outlined"
                      type="tel"
                      fullWidth
                      required
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      helperText={t("Phone Number")}
                      error={!isPhoneNumberValid(phoneNumber)}
                      InputLabelProps={{ shrink: true }}
                      sx={{ marginBottom: 2 }}
                    />
                    <TextField
                      variant="outlined"
                      fullWidth
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      multiline
                      rows={4}
                      helperText={t("Appointment Notes (Optional)")}
                      InputLabelProps={{ shrink: true }}
                      sx={{ marginBottom: 2 }}
                    />
                    <Button
                      onClick={handlePreviousStep}
                      variant="contained"
                      color="primary"
                      sx={{ position: "absolute", bottom: 0, left: 0 }}
                    >
                      {t("Back")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ position: "absolute", bottom: 0, right: 0 }}
                      onClick={handleNextStep}
                      disabled={
                        !step2FormValid ||
                        !isEmailValid(email) ||
                        !isPhoneNumberValid(phoneNumber)
                      }
                    >
                      {t("Next")}
                    </Button>
                  </Box>
                )}
                {activeStep === 2 && (
                  <Box
                    sx={{
                      width: isSmallScreen ? "80%" : "30vw",
                      padding: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginLeft: "5%"
                    }}
                  >
                    <Typography variant="h6" sx={{ marginBottom: 2 }}>
                      {t("Review Details")}
                    </Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                      {t("Service Type")}: <strong>{serviceType}</strong>
                    </Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                      {t("Pick-Up Date")}:{" "}
                      <strong>{convertToDate(pickupDate)}</strong>
                    </Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                      {t("Pick-Up Time")}:{" "}
                      <strong>{convertToAMPM(pickupTime)}</strong>
                    </Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                      {t("Pick-Up Location")}:
                      <br />
                      <strong>{pickupLocation || t("Not specified")}</strong>
                    </Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                      {t("Drop-Off Location")}:
                      <br />
                      <strong>{dropoffLocation || t("Not specified")}</strong>
                    </Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                      {t("Number of Passengers")}: <strong>{passengers}</strong>
                    </Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                      {t("Number of Luggages")}: <strong>{luggage}</strong>
                    </Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                      {t("First Name")}: <strong>{firstName}</strong>
                    </Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                      {t("Last Name")}: <strong>{lastName}</strong>
                    </Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                      {t("Email Address")}: <strong>{email}</strong>
                    </Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                      {t("Phone Number")}: <strong>{phoneNumber}</strong>
                    </Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                      {t("Appointment Notes (Optional)")}:
                      <br />
                      {notes.length > 0 ? <strong>{notes}</strong> : <>N/A</>}
                    </Typography>
                    <Typography sx={{ marginBottom: 1 }}>
                      {t("Return Reservation")}:{" "}
                      <strong>{returnReservation ? "Yes" : "No"}</strong>
                    </Typography>
                    {returnReservation ? (
                      <>
                       <Typography sx={{ marginBottom: 1 }}>
                       {t("Return Pick-Up Date")}:{" "}
                       <strong>{convertToDate(returnPickupDate)}</strong>
                        </Typography>
                        <Typography sx={{ marginBottom: 1 }}>
                          {t("Return Pick-Up Time")}:{" "}
                          <strong>{convertToAMPM(returnPickupTime)}</strong>
                        </Typography>
                        <Typography sx={{ marginBottom: 1 }}>
                          {t("Return Pick-Up Location")}:{" "}
                          <strong>
                            {returnPickupLocation || t("Not specified")}
                          </strong>
                        </Typography>
                      </>
                    ) : (
                      <></>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingTop: "1rem",
                      }}
                    >
                      <Button
                        onClick={handlePreviousStep}
                        variant="contained"
                        color="primary"
                        sx={{ position: "absolute", bottom: 0, left: 0 }}
                      >
                        {t("Back")}
                      </Button>
                      {!loading ? (
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ position: "absolute", bottom: 0, right: 0 }}
                          onClick={handleSubmit}
                        >
                          {t("Submit")}
                        </Button>
                      ) : (
                        <LoadingButton
                          loading
                          loadingIndicator="Loading…"
                          variant="contained"
                          sx={{ position: "absolute", bottom: 0, right: 0 }}
                        >
                          {t("Submit")}
                        </LoadingButton>
                      )}
                    </Box>
                  </Box>
                )}
              </Grid>
              {activeStep === 0 && (
                <Grid item md={6} xs={0}>
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      padding: "0.5rem 0",
                    }}
                  >
                    <MapContainer
                      center={[mapCenter.lat, mapCenter.lng]}
                      zoom={2}
                      style={{ height: "100%", width: "100%" }}
                      onClick={handleMapClick}
                      ref={mapRef}
                    >
                      <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
                    </MapContainer>
                  </div>
                </Grid>
              )}
            </Grid>
          </Box>
        )}
        {submitted && (
          <Card
            elevation={0}
            sx={{
              padding: "2rem",
              marginTop: 2,
            }}
          >
            <CardContent>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" align="center" gutterBottom>
                  {t("Thank you for choosing our services.")}
                </Typography>
                <Typography variant="h5" align="center" gutterBottom>
                  {t("Form submitted successfully!")}
                </Typography>

                <Typography variant="body1" align="center" gutterBottom>
                  {t("Your reservation is confirmed for")}{" "}
                  <strong>{convertToDate(pickupDate)} </strong>
                  {t("at")} <strong>{convertToAMPM(pickupTime)}. </strong>
                  <br />
                  {t("Service Type")}: <strong>{serviceType}</strong>
                  <br />
                  {t("Pick-Up Location")}:{" "}
                  <strong>{pickupLocation || t("Not specified")}</strong>
                  <br />
                  {t("Drop-Off Location")}:{" "}
                  <strong>{dropoffLocation || t("Not specified")}</strong>
                </Typography>
                <div style={{ width: "70%" }}>
                  <Typography variant="body2" align="center" gutterBottom>
                    {t("We have sent a confirmation email to")}{" "}
                    <strong>{email}</strong>.{" "}
                    {t(
                      "Please ensure to check your inbox, and in case you don't see it, kindly check your spam folder as well. We look forward to providing you with a wonderful experience!"
                    )}
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
        )}
      </Paper>
    </Container>
  );
};

export default BookingForm;
