import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import Hero from "../components/Hero";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next"; // Import react-i18next

const CardContainer = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundSize: "cover",
  backgroundPosition: "center",
  borderRadius: "5px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  transition: "box-shadow 0.2s ease-in-out",
  "&:hover": {
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
  },
  width: "100%",
  height: "15rem",
}));

const ContentContainer = styled(CardContent)({
  width: "70%",
});

const AboutUsPage = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <div style={{ overflow: "hidden" }}>
      <Hero img="assets/jet.jpg" text={t("About Us")} />

      <Box
        sx={{
          backgroundColor: "#f2f2f2",
          textAlign: "center",
          padding: isMobile ? "1rem" : "4rem",
        }}
      >
        <Typography
          variant={"inherit"}
          sx={{
            marginBottom: "1rem",
            fontSize: isMobile ? "1.5rem" : "2.5rem",
          }}
        >
          {t("Welcome to Optimus Fleets LLC!")}
        </Typography>

        <Typography
          variant={"inherit"}
          sx={{
            marginBottom: "2rem",
            fontSize: isMobile ? "0.9rem" : "1.2rem",
          }}
        >
          {t("At Optimus Fleets LLC, we are passionate about providing...")}
        </Typography>

        <CardContainer sx={{ marginBottom: "2rem" }}>
          <ContentContainer>
            <Typography
              variant={"inherit"}
              sx={{
                marginBottom: "1rem",
                fontSize: isMobile ? "1.5rem" : "2rem",
              }}
            >
              {t("Our Mission")}
            </Typography>
            <Typography
              variant={"inherit"}
              sx={{
                fontSize: isMobile ? "0.8rem" : "1rem",
              }}
            >
              {t(
                "Our mission at Optimus Fleets LLC is to provide our customers..."
              )}
            </Typography>
          </ContentContainer>
        </CardContainer>
        <CardContainer sx={{ marginBottom: "2rem" }}>
          <ContentContainer>
            <Typography
              variant={"inherit"}
              sx={{
                marginBottom: "1rem",
                fontSize: isMobile ? "1.5rem" : "2rem",
              }}
            >
              {t("Why Choose Us?")}
            </Typography>
            <Typography
              variant={"inherit"}
              sx={{
                fontSize: isMobile ? "0.8rem" : "1rem",
              }}
            >
              {t("- Wide Selection: We have an extensive selection...")}
              <br />
              {t("- Quality Assurance: Our cars are regularly maintained...")}
              <br />
              {t("- Customer Satisfaction: We prioritize customer satisfaction...")}
            </Typography>
          </ContentContainer>
        </CardContainer>
        <CardContainer sx={{ marginBottom: "2rem" }}>
          <ContentContainer>
            <Typography
              variant={"inherit"}
              sx={{
                marginBottom: "1rem",
                fontSize: isMobile ? "1.5rem" : "2rem",
              }}
            >
              {t("Contact Us")}
            </Typography>
            <Typography
              variant={"inherit"}
              sx={{
                fontSize: isMobile ? "0.8rem" : "1rem",
              }}
            >
              {t("Have any questions or want to inquire about a reservation?")}
              <br />
              {t("Email: Optimus Fleets LLC@gmail.com")}
              <br />
              {t("Phone: 1-800-123-4567")}
            </Typography>
          </ContentContainer>
        </CardContainer>
      </Box>
    </div>
  );
};

export default AboutUsPage;
