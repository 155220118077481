import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Box
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link } from "react-router-dom";

const pages = [
  { title: "Home", link: "/" },
  { title: "About Us", link: "/aboutus" },
];

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <React.Fragment>
      <Drawer
        anchor="top"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        {/* Close button at the top of the drawer */}
        <Box sx={{ display: "flex", justifyContent: "center"}}>
          <Button
            sx={{ color: "#d9d3d3", width: "100%", height: "4rem"}}
            onClick={() => setOpenDrawer(false)}
          >
            <KeyboardArrowUpIcon sx={{fontSize: "2rem" }} />
          </Button>
        </Box>
        {/* List items */}
        <List>
          <div style={{ borderBottom: "0.5px solid #d9d3d3" }} />
          {pages.map((page, index) => (
            <ListItemButton
              key={index}
              component={Link}
              to={page.link}
              onClick={() => setOpenDrawer(false)}
              sx={{ borderBottom: "0.5px solid #d9d3d3" }}
            >
              <ListItemIcon>
                <ListItemText
                  primary={page.title}
                  sx={{ fontSize: "1.5rem" }}
                />
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Drawer>
      <IconButton
        sx={{ color: "white", marginLeft: "auto" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon color="white" />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
