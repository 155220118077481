import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Typography, Button, Box, useMediaQuery } from "@mui/material";
import "./Slider.css"; // Import a custom CSS file for styling
import { animated, useSpring } from "@react-spring/web"; // Import react-spring hooks
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../LanguageSwitcher";

const ImageSlider = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleReserveClick = () => {
    navigate("/book");
  };

  const images = [
    process.env.PUBLIC_URL + "/assets/slide1.jpg",
    process.env.PUBLIC_URL + "/assets/slide2.jpg",
    process.env.PUBLIC_URL + "/assets/slide3.jpg",
  ];

  const messages = [
    "Your Journey, Our Expertise: Trusted Chauffeur Services.",
    "Need a chauffeur for a luxury experience?",
    "Elevate Your Ride: Discover Luxury Chauffeur Services.",
  ];

  const [slideIndex, setSlideIndex] = useState(0);
  const [resetAnimation, setResetAnimation] = useState(true);

  const slideAnimation = useSpring({
    opacity: 1,
    transform: "translateY(0px)",
    from: { opacity: 0, transform: "translateY(40px)" },
    config: { tension: 70, friction: 30 },
    reset: resetAnimation,
  });

  const settings = {
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    swipe: "false",
    afterChange: (current, next) => {
      setResetAnimation(true);
    },
    beforeChange: (current, next) => {
      // Update the slide index when the slide changes
      setSlideIndex(next);
      setResetAnimation(false);
    },
  };
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <LanguageSwitcher />
      <Slider {...settings} style={{ overflow: "hidden" }}>
        {images.map((image, index) => (
          <div
            key={index}
            className={`slide-item ${index === slideIndex ? "active" : ""}`}
          >
            <img
              src={image}
              alt={`Image ${index + 1}`}
              style={{ width: "100%", height: "75vh" }}
            />
            <div className="slide-content">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <animated.div key={resetAnimation} style={slideAnimation}>
                  {resetAnimation ? (
                    <>
                      <Typography
                        variant="inherit"
                        component="div"
                        gutterBottom
                        color="white"
                        sx={{ fontSize: isMobile ? "1.5rem" : "2.5rem", paddingTop: isMobile ? '5rem': 0 }}
                      >
                        {t(messages[index])}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleReserveClick}
                        sx={{border: '0.5px solid white', fontSize: '3ex'}}
                      >
                        {t("Book Now")}
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </animated.div>
              </Box>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default ImageSlider;
