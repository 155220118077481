import React from "react";
import DetailsSection from "../components/DetailsSection";
import Slider from "../components/slider/Slider.js";
import OurServices from "../components/OurServices";

const Home = () => {
  return (
    <div style={{overflow: 'hidden'}}>
      <Slider />

      <OurServices />

      <DetailsSection />
      </div>
  );
};

export default Home;
